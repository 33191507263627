import React, { useState } from 'react';
import NearAnalysis from './OptionAnalysis'; // Import NearAnalysis component
import FarAnalysis from './Future'; // Import FarAnalysis component
import Sled from './Sled'; // Import Sled component
import High from './HighVol'
import '../App.css';

const OptionsAnalysisPage = () => {
  const [activeTab, setActiveTab] = useState('near'); // 'near', 'far', or 'sled'

  return (
    <div className="App">
      <div className="tabs">
        <button
          className={`tab-button ${activeTab === 'near' ? 'active' : ''}`}
          onClick={() => setActiveTab('near')}
        >
          Value Strategy
        </button>
        <button
          className={`tab-button ${activeTab === 'far' ? 'active' : ''}`}
          onClick={() => setActiveTab('far')}
        >
          Far Value Strategy
        </button>
        <button
          className={`tab-button ${activeTab === 'sled' ? 'active' : ''}`}
          onClick={() => setActiveTab('sled')}
        >
          Sled Strategy
        </button>
        <button
          className={`tab-button ${activeTab === 'high' ? 'active' : ''}`}
          onClick={() => setActiveTab('high')}
        >
          HVO Strategy
        </button>
      </div>

      {activeTab === 'near' && <NearAnalysis />}
      {activeTab === 'far' && <FarAnalysis />}
      {activeTab === 'sled' && <Sled />}
      {activeTab === 'high' && <High />}
    </div>
  );
};

export default OptionsAnalysisPage;
