import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaGem, FaMedal, FaTrophy, FaStar, FaQuestionCircle } from 'react-icons/fa';
import { useOptionsData } from '../OptionContext'; // Assuming OptionContext is stored in a contexts folder
import '../App.css';

const OptionsAnalysisPage = () => {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [statusMessage, setStatusMessage] = useState('');
  const scoreLegend = [
    { icon: <FaStar className="icon star" />, range: "Above 100", description: "Extraordinary - Highest level of rarity, indicating exceptional potential in the 99.99th percentile." },
    { icon: <FaGem className="icon diamond" />, range: "51-100", description: "Scarce - Rare finds with significant score indicating strong potential." },
    { icon: <FaTrophy className="icon gold" />, range: "21-50", description: "Limited - Less frequently encountered scores with good potential." },
    { icon: <FaMedal className="icon silver" />, range: "11-20", description: "Uncommon - Scores that provide a potential higher than most." },
  ];
  const statusMessages = [
    'Gathering Relevant Stocks...',
    'Analyzing Performance...',
    'Gathering Options Data...',
    'Analyzing Calls...',
    'Analyzing Puts...',
    'Finishing Up...'
  ];

  const { highOptionsData, setHighOptionsData } = useOptionsData();
  
  useEffect(() => {
    // Your fetch logic for future data, similar to what you have but now using setHighOptionsData
    const fetchData = async () => {
      const response = await axios.get('https://api.blockheadlabs.tech/get-high-options-data');
      setHighOptionsData({ calls: response.data.calls, puts: response.data.puts });
    };

    // Check if data is already loaded to prevent refetching
    if (highOptionsData.calls.length === 0 && highOptionsData.puts.length === 0) {
      fetchData();
    }
  }, [setHighOptionsData]);

  const fetchData = async () => {
    setLoading(true);
    setProgress(0);
    setStatusMessage(statusMessages[0]);

    const totalDuration = 2500; // 2.5 minutes in milliseconds
    const updateInterval = 100; // Update progress every second
    let currentStatusIndex = 0;
    let elapsedTime = 0;

    const interval = setInterval(() => {
      elapsedTime += updateInterval;
      const progressPercentage = (elapsedTime / totalDuration) * 100;
      setProgress(progressPercentage);

      const expectedStatusIndex = Math.floor((statusMessages.length * elapsedTime) / totalDuration);
      if (currentStatusIndex !== expectedStatusIndex && expectedStatusIndex < statusMessages.length) {
        currentStatusIndex = expectedStatusIndex;
        setStatusMessage(statusMessages[currentStatusIndex]);
      }

      if (elapsedTime >= totalDuration) {
        clearInterval(interval);
        setStatusMessage('');
        setProgress(100);
      }
    }, updateInterval); 

    try {
      const response = await axios.get('https://api.blockheadlabs.tech/get-high-options-data');
      setHighOptionsData({ calls: response.data.calls, puts: response.data.puts });
    } catch (error) {
      console.error('There was an error fetching the options data:', error);
    } finally {
      setLoading(false);
      clearInterval(interval);
    }
  };

  const getScoreIcon = (score) => {
    if (score > 100) return <FaStar className="icon star" />;
    else if (score > 50) return <FaGem className="icon diamond" />;
    else if (score > 20) return <FaTrophy className="icon gold" />;
    else if (score > 10) return <FaMedal className="icon silver" />;
    return null;
  };

  return (
    <div className="App">
      <div className="header-section">
        <h1 className="dashboard-title">Options Analysis Dashboard</h1>
        <div className="info-icon-container">
          <FaQuestionCircle className="info-icon" title="Info" />
          <div className="popup">Analyzes options using the High Volume Options (HVO) strategy, stocks with options trading volume greater than 100,000.</div>
        </div>
      </div>
      <button className="refresh-button" onClick={fetchData}>Analyze</button>
      {loading ? (
        <>
          <div className="progress-container">
            <div className="progress-bar" style={{ width: `${progress}%` }}></div>
          </div>
          <div className="status-message">{statusMessage}</div>
        </>
      ) : (
        renderContent()
      )}
    </div>
  );

  function renderContent() {
    if (loading || (!highOptionsData.calls.length && !highOptionsData.puts.length)) {
        return null; // Don't render anything if loading or no data available
      }
    return (
      <>
        <div className="legend-heading">
          <h3 className='legend-title-box'>Score Legend</h3>
          <div className="legend-container">
            {scoreLegend.map((item, index) => (
              <div key={index} className="legend-item">
                {item.icon}
                <span className="legend-range">{item.range}</span>
                <span className="legend-description">{item.description}</span>
              </div>
            ))}
          </div>
        </div>
        <div className="options-section">
          <h2>Top 5 Calls</h2>
          <div className="options-container">
            {highOptionsData.calls.map((call, index) => (
              <OptionItem key={index} option={call} index={index} iconGetter={getScoreIcon} optionType="call" />
            ))}
          </div>
        </div>
        <div className="options-section">
          <h2 className='put'>Top 5 Puts</h2>
          <div className="options-container">
            {highOptionsData.puts.map((put, index) => (
              <OptionItem key={index} option={put} index={index} iconGetter={getScoreIcon} optionType="put" />
            ))}
          </div>
        </div>
      </>
    );
  }  
}

const OptionItem = ({ option, index, iconGetter, optionType }) => (
  <a 
    href={`https://robinhood.com/options/chains/${option.Ticker}`} 
    target="_blank" 
    rel="noopener noreferrer" 
    className={`option-item ${optionType}-option`}
  >
    <div className="rank-badge">{index + 1}</div>
    <div className="metric ticker"><span>{option.Ticker}</span></div>
    <div className="metric">Strike Price: <span>${option.strike.toFixed(2)}</span></div>
    <div className="metric">Ask Price: <span>${option.ask.toFixed(2)}</span></div>
    <div className="metric">DTE: <span>{option.daysToExpiration}</span></div>
    <div className={`score-pill ${optionType}-score`}>Score: <span className={`score-value ${optionType}`}>{option.Score.toFixed(1)}</span>{iconGetter(option.Score)}</div>
  </a>
);


export default OptionsAnalysisPage;
