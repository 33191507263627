import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import axios from 'axios';

// Register the necessary Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const timeframes = {
  '1d': 'Today',
  '5d': '5 Days',
  '1mo': '1 Month',
  '3mo': '3 Months',
  '1y': '1 Year',
  '5y': '5 Years',
  'max': 'Max'
};

const StockChart = () => {
  const [period, setPeriod] = useState('5d');
  const [ticker, setTicker] = useState('');
  const [chartData, setChartData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // Fetch stock data when the ticker or period changes
  useEffect(() => {
    if (ticker && period) {
      fetchStockData();
    }
  }, [ticker, period]);

  // Fetch stock data from the API
  const fetchStockData = async () => {
    setLoading(true);
    setError('');

    try {
      const response = await axios.get(`https://api.blockheadlabs.tech/api/stock/${ticker.trim().toUpperCase()}?period=${period}`);
      const data = response.data;
      console.log(data)
      setChartData({
        labels: data.dates,
        datasets: [{
          label: 'Closing Price',
          data: data.prices,
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1
        }]
      });
    } catch (error) {
      console.error('Error fetching stock data:', error);
      setError('Failed to fetch data. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="stock-chart-container">
      <h3 style={{color: '#1ca3cc'}}>{ticker ? `${ticker.toUpperCase()} Historical Data` : 'Stock Historical Data'}</h3>
      <div className="stock-controls">
        <input
          className="stock-input"
          type="text"
          value={ticker}
          onChange={(e) => setTicker(e.target.value.toUpperCase())}
          onKeyPress={(e) => e.key === 'Enter' && fetchStockData()}
          placeholder="Enter Ticker Symbol"
        />
        <select
          className="timeframe-select"
          value={period}
          onChange={(e) => setPeriod(e.target.value)}
        >
          {Object.entries(timeframes).map(([value, label]) => (
            <option key={value} value={value}>{label}</option>
          ))}
        </select>
      </div>
      {loading && <div className="loader">Loading...</div>}
      {error && <div className="error-message">{error}</div>}
      {chartData.labels && (
        <Line data={chartData} />
      )}
    </div>
  );
};

export default StockChart;



