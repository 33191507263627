import React, { useState } from 'react';
import Baseball from './Baseball'; // Import Baseball component
import BaseballCharts from './BaseballAnalytics'; // Import BaseballCharts component
import Parlays from './BaseballParlays'
import '../App.css';

const BaseballNav = () => {
  const [activeTab, setActiveTab] = useState('baseball'); // 'baseball' or 'charts'

  return (
    <div className="App">
      <div className="tabs">
      <div class="tabs-border">
        <button
          className={`tab-button ${activeTab === 'baseball' ? 'active' : ''}`}
          onClick={() => setActiveTab('baseball')}
        >
          Games
        </button>
        <button
          className={`tab-button ${activeTab === 'Parlays' ? 'active' : ''}`}
          onClick={() => setActiveTab('Parlays')}
        >
          Parlays
        </button>
        <button
          className={`tab-button ${activeTab === 'charts' ? 'active' : ''}`}
          onClick={() => setActiveTab('charts')}
        >
          Charts
        </button>
      </div>
      </div>
      {activeTab === 'baseball' && <Baseball />}
      {activeTab === 'Parlays' && <Parlays />}
      {activeTab === 'charts' && <BaseballCharts />}
    </div>
  );
};

export default BaseballNav;
