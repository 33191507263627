import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Select from 'react-select';

const PitcherStatsChart = () => {
    const [pitchers, setPitchers] = useState([]);
    //const [selectedStat, setSelectedStat] = useState(null);
    const [chartData, setChartData] = useState([]);
    const [selectedPitchers, setSelectedPitchers] = useState([]);
    const [selectedStats, setSelectedStats] = useState([]);
    const [minSeason, setMinSeason] = useState(null);
    const [maxSeason, setMaxSeason] = useState(null);


    const statsOptions = [
        { value: 'era', label: 'ERA' },
        { value: 'strikeouts', label: 'Strikeouts' },
        { value: 'whip', label: 'WHIP' },
        { value: 'innings_pitched', label: 'Innings Pitched' },
        { value: 'runs', label: 'Runs' },
        { value: 'home_runs', label: 'Home Runs' },
        { value: 'hits', label: 'Hits' },
        { value: 'wins', label: 'Wins' },
        { value: 'losses', label: 'Losses' },
        { value: 'saves', label: 'Saves' },
        { value: 'strikes', label: 'Strikes' },
        // Add other stats as needed
    ];

    // Fetch unique pitchers list
    useEffect(() => {
      axios.get('https://api.blockheadlabs.tech/get-pitchers') // Adjust to your API endpoint
          .then(response => {
              const uniquePitchers = response.data.map(pitcher => ({
                  value: pitcher.pitcher_name,
                  label: pitcher.pitcher_name
              }));
              setPitchers(uniquePitchers);
          })
          .catch(error => console.error('Error fetching pitchers:', error));
    }, []);
  

      // Fetch data for the selected pitcher and stat

      useEffect(() => {
        if (selectedPitchers.length && selectedStats.length) {
          // Generate all combinations of pitchers and stats
          const dataFetchPromises = selectedPitchers.flatMap(pitcher =>
            selectedStats.map(stat => {
              return axios
                .get(`https://api.blockheadlabs.tech/get-pitchhist`, {
                  params: {
                    pitcher_name: pitcher.value,
                    stat: stat.value,
                  },
                })
                .then((response) => {
                  // Format and return the series data here
                  return {
                    name: `${pitcher.label} - ${stat.label}`,
                    data: response.data.map((d) => ({
                      season: d.season.toString(), // Ensure season is a string
                      value: d[stat.value], // Use the stat value
                    })),
                  };
                });
            })
          );
      
          Promise.all(dataFetchPromises)
            .then((series) => {
              // Flatten series data to find the global min and max seasons
              const allData = series.flatMap(s => s.data);
              const allSeasons = Array.from(new Set(allData.map(d => d.season))).sort();
              const minSeason = allSeasons[0];
              const maxSeason = allSeasons[allSeasons.length - 1];
              setMinSeason(minSeason);
              setMaxSeason(maxSeason);
      
              // Filter out unwanted seasons and sort
              series.forEach(s => {
                s.data = s.data.filter(d => d.season >= minSeason && d.season <= maxSeason);
                s.data.sort((a, b) => a.season - b.season);
              });
      
              setChartData(series);
            })
            .catch((error) => {
              console.error('Error fetching data:', error);
            });
        } else {
          setChartData([]); // Reset the chart data if no selections
        }
      }, [selectedPitchers, selectedStats]);
      

    
    const values = chartData.flatMap(series => series.data.map(item => item.value));
    const minValue = Math.min(...values);
    const maxValue = Math.max(...values);
    const range = maxValue - minValue;
  
    // Define a fixed set of colors for pitchers
    const pitcherColors = [
      '#FF6347', // Red
      '#4682B4', // Blue
      '#32CD32', // Green
      '#FFD700', // Yellow
      '#6A5ACD', // Purple
      // Add more as needed
    ];

    // Define line styles for different statistics
    const statStyles = {
      'ERA': "5 5", // Dashed
      'Strikeouts': "3 3", // Dotted
      'WHIP': "0", // Solid
      'Innings Pitched': "7 3 3 3", // Dash-dot
      'Runs': "15 5 3 5", // Long dash, short dash
      'Home Runs': "1 2 3 3",
      'Hits': '22 2 2 1',
      'Wins': '13 12 1 1',
      'Losses': '11 2 24 1',
      'Saves': '12 12 3 4',
      'Strikes': '22 22 22 1'
      
    };

  
    const CustomTooltip = ({ active, payload, label }) => {
      if (active && payload && payload.length) {
        return (
          <div className="custom-tooltip">
            <p>{`Season: ${label}`}</p>
            <p>{`${selectedStats ? selectedStats.label : ''}: ${payload[0].value}`}</p>
          </div>
        );
      }
    
      return null;
    };

    const customStyles = {
      control: (provided, state) => ({
        ...provided,
        backgroundColor: '#1ca3cc33', // Dropdown background color
        borderColor: state.isFocused ? '#1ca3cc' : '#1ca3cc',
        // Control text color not specified here, assuming it is already handled
      }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#1ca3cc' : 'transparent', // Background color for selected/unselected option
        color: '#ffffff', // Text color for options is set to white
        '&:hover': {
          backgroundColor: '#1ca3cc', // Background color on hover
          color: '#ffffff', // Text color on hover is set to white
        },
      }),
      singleValue: (provided) => ({
        ...provided,
        color: '#ffffff', // Text color for the selected item shown in the dropdown control
      }),
      menu: (provided) => ({
        ...provided,
        backgroundColor: '#1ca3cc33', // Background color for the dropdown menu
        // Menu text color not specified here, it's controlled by 'option'
      }),
      // ... include other style properties as needed
    };

    return (
      <div className="chart-container">
          <div className="selectors-container">
          <Select
              className="pitcher-select"
              options={pitchers}
              onChange={(value) => setSelectedPitchers((prevSelected) => value || [])} // Handle null value by setting an empty array
              placeholder="Select Pitchers"
              isClearable={true}
              isMulti={true}
              isSearchable={true}
              styles={customStyles}
              value={selectedPitchers} // Reflect the current value
          />

          <Select
              className="stat-select"
              options={statsOptions}
              onChange={(value) => setSelectedStats(value || [])} // Handle null value by setting an empty array
              placeholder="Select Stats"
              isClearable={true}
              isMulti={true}
              isSearchable={true}
              styles={customStyles}
              value={selectedStats} // Reflect the current value
          />

          </div>
          <ResponsiveContainer width="200%" height={600} className="chart">
              <LineChart data={chartData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="season" type="number" domain={[minSeason, maxSeason]} />
                  <YAxis
                      type="number"
                      domain={[
                          parseFloat((0 - range * 0).toFixed(2)),
                          parseFloat((maxValue + range * 0.5).toFixed(0))
                      ]}
                      allowDataOverflow={true}
                  />
                  <Tooltip content={<CustomTooltip />} />
                  <Legend />
                  {
                      chartData.map((series, index) => {
                          const pitcherIndex = selectedPitchers.findIndex(p => p.label === series.name.split(' - ')[0]);
                          const statName = series.name.split(' - ')[1];
                          const color = pitcherColors[pitcherIndex % pitcherColors.length];
                          const lineStyle = statStyles[statName] || "0"; // Default to solid if not defined

                          return (
                              <Line
                                  key={index}
                                  type="monotone"
                                  dataKey="value"
                                  data={series.data}
                                  name={series.name}
                                  stroke={color}
                                  strokeDasharray={lineStyle}
                                  activeDot={{ r: 8 }}
                              />
                          );
                      })
                  }
              </LineChart>
          </ResponsiveContainer>


      </div>
  );
  };

export default PitcherStatsChart;

// Helper function to generate random colors for the lines
function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

