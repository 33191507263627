import React, { createContext, useContext, useState } from 'react';

const OptionDataContext = createContext();

export const useOptionsData = () => useContext(OptionDataContext);

export const OptionDataProvider = ({ children }) => {
  const [regularOptionData, setRegularOptionData] = useState({ calls: [], puts: [] });
  const [futureOptionData, setFutureOptionData] = useState({ calls: [], puts: [] });
  const [sledOptionsData, setSledOptionsData] = useState({ calls: [], puts: [] });
  const [highOptionsData, setHighOptionsData] = useState({ calls: [], puts: [] });


  return (
    <OptionDataContext.Provider value={{
      regularOptionData,
      setRegularOptionData,
      futureOptionData,
      setFutureOptionData,
      sledOptionsData, 
      setSledOptionsData,
      highOptionsData,
      setHighOptionsData
    }}>
      {children}
    </OptionDataContext.Provider>
  );
};
