import React from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';
import { useEffect } from 'react';  // Correctly import useEffect from 'react'
import HomePage from './components/HomePage';
import OptionAnalysis from './components/OptionAnalysis';
import Future from './components/Future';
import FAQ from './components/FAQ';
import './App.css';
import { OptionDataProvider } from './OptionContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';
import TopScores from './components/Stats';
import OptionsAnalysisPage from './components/Options';
import Stocks from './components/Stocks';
import LLM from './components/LLM';
import Baseball from './components/Baseball';
import Sports from './components/Sports';

function App() {
  return (
    <OptionDataProvider>
      <Router>
        <nav className="navbar">
          
          {/* Separate the nav-links and discord-icon into two divs for better alignment */}
          <div className="nav-container">
            <ul className="nav-links">
              <li><NavLink to="/" className={({ isActive }) => (isActive ? 'active-link' : '')}>Home</NavLink></li>
              <li><NavLink to="/stocks" className={({ isActive }) => (isActive ? 'active-link' : '')}>Stocks</NavLink></li>
              <li><NavLink to="/options" className={({ isActive }) => (isActive ? 'active-link' : '')}>Options</NavLink></li>
              <li><NavLink to="/llm" className={({ isActive }) => (isActive ? 'active-link' : '')}>Chuck</NavLink></li>
              {/* <li><NavLink to="/option-analysis" className={({ isActive }) => (isActive ? 'active-link' : '')}>Near Analysis</NavLink></li>
              <li><NavLink to="/future-option-analysis" className={({ isActive }) => (isActive ? 'active-link' : '')}>Far Analysis</NavLink></li> */}
              <li><NavLink to="/sports" className={({ isActive }) => (isActive ? 'active-link' : '')}>Sports</NavLink></li>
              <li><NavLink to="/stats" className={({ isActive }) => (isActive ? 'active-link' : '')}>Stats</NavLink></li>
              <li><NavLink to="/faq" className={({ isActive }) => (isActive ? 'active-link' : '')}>FAQ</NavLink></li>
            </ul>
          </div>
          <h1 className="navbar-title">Blockhead Labs</h1>
          <div className="discord-link-container">
            <a href="https://discord.gg/Mg4mSHf2aE" target="_blank" rel="noopener noreferrer" className="discord-icon">
              <FontAwesomeIcon icon={faDiscord} />
            </a>
          </div>
        </nav>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/option-analysis" element={<OptionAnalysis />} />
          <Route path="/future-option-analysis" element={<Future />} />
          <Route path="/stats" element={<TopScores />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/options" element={<OptionsAnalysisPage />} />
          <Route path="/stocks" element={<Stocks />} />
          <Route path="/llm" element={<LLM />} />
          <Route path="/sports" element={<Sports />} />
        </Routes>
      </Router>
    </OptionDataProvider>
  );
}

export default App;
