import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaSpinner, FaRedo } from 'react-icons/fa';
import '../App.css'; // Ensure this path is correct for your project

const BaseballPredictionsPage = () => {
  const [loading, setLoading] = useState(true);
  const [predictions, setPredictions] = useState(() => {
    const savedPredictions = localStorage.getItem('predictions');
    return savedPredictions ? JSON.parse(savedPredictions) : [];
  });

  function formatGameTime(timestamp) {
    const date = new Date(timestamp);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  }

  const fetchPredictions = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://api.blockheadlabs.tech/run-predictions');
      setPredictions(response.data);
      localStorage.setItem('predictions', JSON.stringify(response.data));
    } catch (error) {
      console.error('There was an error fetching the predictions:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (predictions.length === 0) {
      fetchPredictions();
    } else {
      setLoading(false);
    }
  }, [predictions.length]);

  const handleRefresh = () => {
    fetchPredictions();
  };

  const getTeamLogo = (teamName) => {
    const formattedTeamName = teamName.replace(/\s/g, '_');
    return `/team_logos/${formattedTeamName}.gif`;
  };

  

  return (
    <div className="App">
      <div className="header-section">
        <h1 className="dashboard-title">Baseball Predictions</h1>
        <button onClick={handleRefresh} className="refresh-baseball">
          <FaRedo />
        </button> {/* Refresh button */}
      </div>
      {loading ? (
        <FaSpinner className="spinner" />
      ) : (
        <div className="predictions-container">
          {predictions.map((prediction, index) => (
            <div key={index} className="prediction-item">
              <div className="team-box">
                <div className="team-info">
                  <img src={getTeamLogo(prediction.away_name)} alt={prediction.away_name} className="team-logo pill" />
                  <a href="https://www.espn.com/mlb/scoreboard" target="_blank" rel="noopener noreferrer" className="team-name-link">
                    <span className="team-name">{prediction.away_name}</span>
                  </a>
                  {/* Displaying away_pp below the team name */}
                  <div className="team-small-info pill">{prediction.away_pp}</div>
                  <div className="team-info-stack">
                    <div className="score pill">{prediction.predicted_away_score.toFixed(2)}</div>
                    <div className="spread pill">{prediction.predicted_home_score > prediction.predicted_away_score ? "+" : "-"}{Math.abs(prediction.predicted_spread).toFixed(1)}</div>
                    {prediction.predicted_winner === prediction.away_name ? <div className="icon win pill">✅</div> : <div className="icon lose pill">❌</div>}
                  </div>
                  <div className="odds-info pill" style={{
                    position: 'absolute', top: 10, right: 10, backgroundColor: '#2a2a2a'
                  }}>
                    {prediction.away_odds}
                  </div>
                </div>
                <div className="streak-info pill" style={{
                  backgroundColor: prediction.away_win_or_loss === 'W' ? '#28a745' : '#dc3545'
                }}>
                  <span className="streak">{prediction.away_streak}{prediction.away_win_or_loss}</span>
                </div>
              </div>
              <div className="baseball-icon-container">
                <div className="game-time pill">{formatGameTime(prediction.game_datetime)}</div>
                <div className='baseball-icon'>⚾️</div>
                <div className="total-runs pill">Runs {prediction.total_runs.toFixed(1)}</div>
              </div>
              <div className="team-box">
                <div className="team-info">
                  <img src={getTeamLogo(prediction.home_name)} alt={prediction.home_name} className="team-logo pill" />
                  <a href="https://www.espn.com/mlb/scoreboard" target="_blank" rel="noopener noreferrer" className="team-name-link">
                    <span className="team-name">{prediction.home_name}</span>
                  </a>
                  {/* Displaying home_pp below the team name */}
                  <div className="team-small-info pill">{prediction.home_pp}</div>
                  <div className="team-info-stack">
                    <div className="score pill">{prediction.predicted_home_score.toFixed(2)}</div>
                    <div className="spread pill">{prediction.predicted_home_score > prediction.predicted_away_score ? "-" : "+"}{Math.abs(prediction.predicted_spread).toFixed(1)}</div>
                    {prediction.predicted_winner === prediction.home_name ? <div className="icon win pill">✅</div> : <div className="icon lose pill">❌</div>}
                  </div>
                  <div className="odds-info pill" style={{
                    position: 'absolute', top: 10, right: 10, backgroundColor: '#2a2a2a'
                  }}>
                    {prediction.home_odds}
                  </div>
                </div>
                <div className="streak-info pill" style={{
                    backgroundColor: prediction.home_win_or_loss === 'W' ? '#28a745' : '#dc3545'
                  }}>
                  <span className="streak">{prediction.home_streak}{prediction.home_win_or_loss}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
  
};

export default BaseballPredictionsPage;
