import React from 'react';
import '../App.css'; // Assuming your CSS file is stored here

const FAQ = () => {
    return (
        <div className="faq-container">
            <h1 className="faq-header">Frequently Asked Questions</h1>

{/*             <div className="tabs">
                <button className="tab-button active">Sports Betting Analytics</button>
                <button className="tab-button">Options Analysis Dashboard</button>
            </div> */}

            <div className="faq-content">
                <h2 style={{color: '#1ca3cc'}}>What is next-gen sports analytics? 🏈</h2>
                <p>
                    Leveraging over 300 million data points from official league APIs, our models perform deep analysis to provide cutting-edge sports betting and fantasy sports insights.
                </p>
                <h2 style={{color: '#1ca3cc'}}>How do we model predictions? 📈</h2>
                <p>
                    We deploy an army of predictive models, synthesizing their outputs to form a master model that delivers high-accuracy forecasts for sports events.
                </p>
                <h2 style={{color: '#1ca3cc'}}>How does the Options Analysis Dashboard work? 📊</h2>
                <p>
                    Our dashboard applies sophisticated financial models like Black-Scholes-Merton, Brownian Motion, Ito Calculus, and Gamma Exposure Index to score investment opportunities.
                </p>
                <h2 style={{color: '#1ca3cc'}}>What makes our scoring system reliable? 🔍</h2>
                <p>
                    Our scoring system is derived from complex algorithms that analyze market conditions and potential risks, aiming to pinpoint the most promising options.
                </p>
            </div>

            <footer>
                <p>Need more info? Join our <a href="https://discord.gg/Mg4mSHf2aE">Discord community</a>!</p>
            </footer>
        </div>
    );
};

export default FAQ;
