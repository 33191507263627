import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../App.css'; // Ensure this path is correct for your CSS file

function TopScores() {
    const [topUniqueCallScores, setTopUniqueCallScores] = useState([]);
    const [topAvgCallScores, setTopAvgCallScores] = useState([]);
    const [topUniquePutScores, setTopUniquePutScores] = useState([]);
    const [topAvgPutScores, setTopAvgPutScores] = useState([]);
    const [topMispricedTickers, setTopMispricedTickers] = useState([]); // New state for mispriced tickers
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);

        // API calls for unique and average call/put scores
        const fetchUniqueCallScores = axios.get('http://127.0.0.1:5000/api/top-unique-calls');
        const fetchAvgCallScores = axios.get('http://127.0.0.1:5000/api/top-avg-calls');
        const fetchUniquePutScores = axios.get('http://127.0.0.1:5000/api/top-unique-puts');
        const fetchAvgPutScores = axios.get('http://127.0.0.1:5000/api/top-avg-puts');
        const fetchTopMispricedTickers = axios.get('http://127.0.0.1:5000/api/top-mispriced-tickers'); // New API call

        axios.all([fetchUniqueCallScores, fetchAvgCallScores, fetchUniquePutScores, fetchAvgPutScores, fetchTopMispricedTickers])
            .then(axios.spread((...responses) => {
                setTopUniqueCallScores(responses[0].data);
                setTopAvgCallScores(responses[1].data);
                setTopUniquePutScores(responses[2].data);
                setTopAvgPutScores(responses[3].data);
                setTopMispricedTickers(responses[4].data); // Set data for mispriced tickers
                setLoading(false);
            }))
            .catch(errors => {
                console.error('There was an error fetching the scores!', errors);
                setLoading(false);
            });
    }, []);

    return (
        <div className="scores-container">
            <h2>Top Option Scores - This page requires financial support join the slack! -</h2>
            {loading ? (
                <div className="spinner"></div>
            ) : (
                <>
                    <div className="score-section">
                        <h3>Top Call Scores by Ticker</h3>
                        <ul className="scores-list">
                            {topUniqueCallScores.map((item, index) => (
                                <li key={index}>
                                    <span className="ticker">{item.Ticker}</span>
                                    <span className="score">{item.Score.toFixed(0)}</span>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="score-section">
                        <h3>Top Average Call Scores by Ticker</h3>
                        <ul className="scores-list">
                            {topAvgCallScores.map((item, index) => (
                                <li key={index}>
                                    <span className="ticker">{item.Ticker}</span>
                                    <span className="score">{item.AvgScore.toFixed(0)}</span>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="score-section">
                        <h3>Top Put Scores by Ticker</h3>
                        <ul className="scores-list">
                            {topUniquePutScores.map((item, index) => (
                                <li key={index}>
                                    <span className="ticker">{item.Ticker}</span>
                                    <span className="score">{item.Score.toFixed(0)}</span>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className="score-section">
                        <h3>Top Average Put Scores by Ticker</h3>
                        <ul className="scores-list">
                            {topAvgPutScores.map((item, index) => (
                                <li key={index}>
                                    <span className="ticker">{item.Ticker}</span>
                                    <span className="score">{item.AvgScore.toFixed(0)}</span>
                                </li>
                            ))}
                        </ul>
                    </div>

                    {/* New section for top mispriced tickers */}
                    <div className="score-section">
                        <h3>Top Mispriced Tickers by Average Ratio</h3>
                        <ul className="scores-list">
                            {topMispricedTickers.map((item, index) => (
                                <li key={index}>
                                    <span className="ticker">{item.Ticker}</span>
                                    <span className="score">{item.AvgMispricingRatio.toFixed(2)}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </>
            )}
        </div>
    );
}

export default TopScores;
