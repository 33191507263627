import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../App.css'; // Make sure the path matches your project structure

const BaseballParlays = () => {
    const [loading, setLoading] = useState(true);
    const [parlays, setParlays] = useState({});
    const [error, setError] = useState('');

    const fetchParlays = async () => {
        setLoading(true);
        setError('');
        try {
            const response = await axios.get('https://api.blockheadlabs.tech/get-parlays');
            setParlays(response.data);
        } catch (error) {
            console.error('There was an error fetching the parlays:', error);
            setError('Failed to fetch parlays. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchParlays();
    }, []);

    const getTeamLogo = (teamName) => {
        const formattedTeamName = teamName.replace(/\s/g, '_');
        return `/team_logos/${formattedTeamName}.gif`;
    };

    return (
        <div className="App">
            <div className="header-section">
                <h1 className="dashboard-title">Baseball Parlays</h1>
            </div>
            {loading ? (
                <p>Loading parlays...</p>
            ) : error ? (
                <p className="error">{error}</p>
            ) : (
                <div className="parlays-container">
                    <div className="three-game-parlays">
                        <h2 style={{ color: '#1ca3cc' }}>Three-Game Parlays</h2>
                        <div className="parlay-row">
                            {parlays.threeGameParlays && parlays.threeGameParlays.map((parlay, index) => (
                                <div key={index} className="parlay-item">
                                    {[{ team: parlay.Team1, prob: parlay.Team1_prob }, { team: parlay.Team2, prob: parlay.Team2_prob }, { team: parlay.Team3, prob: parlay.Team3_prob }].map(({ team, prob }) => (
                                        <div className="team-box" key={team}>
                                            <img src={getTeamLogo(team)} alt={team} className="team-logo" />
                                            <span className="team-name">{team}</span>
                                            <span className="team-probability">Win Probability {(prob * 100).toFixed(2)}%</span>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="five-game-parlays">
                        <h2 style={{ color: '#1ca3cc' }}>Five-Game Parlays</h2>
                        <div className="parlay-row">
                            {parlays.fiveGameParlays && parlays.fiveGameParlays.map((parlay, index) => (
                                <div key={index} className="parlay-item">
                                    {[{ team: parlay.Team1, prob: parlay.Team1_prob }, { team: parlay.Team2, prob: parlay.Team2_prob }, { team: parlay.Team3, prob: parlay.Team3_prob }, { team: parlay.Team4, prob: parlay.Team4_prob }, { team: parlay.Team5, prob: parlay.Team5_prob }].map(({ team, prob }) => (
                                        <div className="team-box" key={team}>
                                            <img src={getTeamLogo(team)} alt={team} className="team-logo" />
                                            <span className="team-name">{team}</span>
                                            <span className="team-probability">Win Probability {(prob * 100).toFixed(2)}%</span>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BaseballParlays;
