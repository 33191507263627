import React, { useState } from 'react';
import '../App.css'; // Make sure this path is correct for your CSS file
import axios from 'axios';

function LLM() {
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState('');
  const [error, setError] = useState('');

  const sendMessage = async () => {
    if (inputText.trim() === '') return;  // Check if the input is not empty
  
    const newMessage = { text: inputText, sender: 'user' };
    setMessages((prevMessages) => [...prevMessages, newMessage]);  // Add the user's message to the chat
  
    try {
      // Send the message to your server endpoint, which in turn sends it to OpenAI's API
      const response = await axios.post('http://127.0.0.1:5000/send_message', { message: inputText });
  
      // Receive the AI's response and add it to the chat
      setMessages((prevMessages) => [...prevMessages, { text: response.data.reply, sender: 'ai' }]);
    } catch (e) {
      // If there is an error, log it and set an error state
      console.error('Error sending message:', e);
      setError('Failed to send message. Please try again later.');
    }
  
    setInputText('');  // Clear the input field
  };
  
  return (
    <div className="App">
      <header className="App-header">
        <h1>Chuck - BETA</h1>
      </header>
      {error && <div className="error">{error}</div>}
      <div className="chat-container">
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.sender}`}>
            {message.text}
          </div>
        ))}
      </div>
      <div className="input-container">
        <input
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
          placeholder="Type your message here..."
        />
        <button onClick={sendMessage}>Send</button>
      </div>
    </div>
  );
}

export default LLM;
