import React, { useState } from 'react';
import Baseball from './Baseball'; // Import Baseball component
import BaseballCharts from './BaseballAnalytics'; // Import BaseballCharts component
import Football from './Football'
import BaseballNav from './BaseballNav'
import '../App.css';

const BaseballDashboardPage = () => {
  const [activeTab, setActiveTab] = useState('baseball'); // 'baseball' or 'charts'

  return (
    <div className="App">
      <div className="tabs">
        <button
          className={`tab-button ${activeTab === 'baseball' ? 'active' : ''}`}
          onClick={() => setActiveTab('baseball')}
        >
          Baseball
        </button>
        <button
          className={`tab-button ${activeTab === 'football' ? 'active' : ''}`}
          onClick={() => setActiveTab('football')}
        >
          Football
        </button>
      </div>

      {activeTab === 'baseball' && <BaseballNav />}
      {activeTab === 'football' && <Football />}
    </div>
  );
};

export default BaseballDashboardPage;
