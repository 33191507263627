import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you're using react-router for navigation

const HomePage = () => {
  return (
    <div className="home-container">
      <header className="home-header">
        <h1>Ball, Stocks, and Numbers</h1>
        <p>Your comprehensive dashboard for sports analytics, stock insights, and financial trading tools.</p>
      </header>
      
      <section className="introduction">
        <h2 style={{color: '#1ca3cc'}}>Welcome to Your All-in-One Analytics Platform</h2>
        <p>Explore a world of data-driven insights for sports betting, stock market analysis, fantasy sports, and options trading—all in one place.</p>
      </section>
      
      <section className="features-overview-title">
        <h4 >Features Overview</h4>
        <div className="features-container">
          <div className="feature-item">
            <h3>⚾️🏈 Sports Betting 🏀🏒</h3>
            <p style={{color: '#fff'}}>Access advanced analytics to make smarter bets in real-time.</p>
          </div>
          <div className="feature-item">
            <h3>📉 Stock Market 📈</h3>
            <p style={{color: '#fff'}}>Dive into the stock market with data visualizations and trend predictions.</p>
          </div>
          <div className="feature-item">
            <h3>Options Trading 📊</h3>
            <p style={{color: '#fff'}}>Utilize our tools to strategize and optimize your options trading.</p>
          </div>
          <div className="feature-item">
            <h3>Fantasy Sports 🏟️</h3>
            <p style={{color: '#fff'}}>Get the edge in your fantasy leagues with our expert analysis and player stats.</p>
          </div>
        </div>
      </section>
      
      <section className="preview">
        <h3>Interactive Tour</h3>
        <p>Take a quick tour of our dashboard and see how easy it is to start analyzing options.</p>
        {/* Placeholder for video or interactive guide */}
        <div className="video-preview">
          <img src="/path/to/dashboard-tour.jpg" alt="Dashboard tour preview" />
        </div>
      </section>
      
      <section className="testimonials">
        <h3>What Our Users Say</h3>
        <p>Don’t just take our word for it—see what our users have to say about how our platform has enhanced their decision-making process.</p>
        {/* Embed testimonials */}
      </section>
      
      <footer className="contact-info">
        <h3>Contact Us</h3>
        <p>Have questions or feedback? Reach out to us!</p>
      </footer>
    </div>
  );
  
  
}

export default HomePage;
